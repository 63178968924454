// src/components/Footer.tsx
import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <p><a href="https://instagram.com/mute_un.mute" target="_blank"><i className="bi bi-instagram icons"></i></a></p>
      <p className="copyright">&copy; 2024 mute-un-mute by Josh Yeung. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;