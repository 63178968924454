// src/App.tsx
import React from 'react';
import './App.css';
import GradientBackground from './components/GradientBackground';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';
import Info from './pages/Info';
import Contact from './pages/Contact';

const App: React.FC = () => {
  return (

      <Router>
        <GradientBackground />
        <div className="App">
          <div className="page-container">
            <Navbar />
            <main>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/info" element={<Info />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </div>
      </Router>

  );
};

export default App;