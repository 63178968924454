// src/pages/Contact.tsx
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
  useEffect(() => {
    // Set the document title
    document.title = 'mute-un-mute | Contact';

    // Create the canonical link element
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    canonicalLink.href = 'https://mute-un-mute.com/contact';

    // Append the canonical link to the head
    document.head.appendChild(canonicalLink);

    // Cleanup function to remove the canonical link when the component unmounts
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="description" content="Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists." />
        <meta name="keywords" content="music production, hong kong, songwriting, music arrangements, Josh Yeung, mute-un-mute, mute-unmute, mute, unmute, j0sh, music producer, music production services, JKNC, commercial music, tvc music" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Mute-Un-Mute",
              "url": "http://www.mute-un-mute.com",
              "logo": "http://www.mute-un-mute.com/logo.png",
              "sameAs": "https://www.instagram.com/mute_un.mute",
              "founder": {
                "@type": "Person",
                "name": "Josh Yeung"
              },
              "description": "Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists."
            }
          `}
        </script>
      </Helmet>
      <h1>Contact</h1>
      <p>< a href="mailto:josh@mute-un-mute.com">josh@mute-un-mute.com</a></p>
    </div>
  );
};

export default Contact;