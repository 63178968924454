export const applyScrollEffect = () => {
  const brands = document.querySelector('.brands');
  const ul = brands.querySelector('ul');
  const lis = ul.querySelectorAll('li');
  const viewportHeight = window.innerHeight;
  const centerY = viewportHeight / 2;

  lis.forEach((li) => {
    const rect = li.getBoundingClientRect();
    const distanceFromCenter = Math.abs(centerY - (rect.top + rect.height / 2));
    const maxDistance = viewportHeight / 2;
    const distanceRatio = Math.min(distanceFromCenter / maxDistance, 1);
    const scale = 1 + (1 - distanceRatio) * 1; // Max scale factor
    const fontSize = 16 + (1 - distanceRatio) * 4; // Max font size increment
    const opacity = 1 - distanceRatio; // Opacity decreases with distance

    li.style.transform = `scale(${scale})`;
    li.style.fontSize = `${fontSize}px`;
    li.style.opacity = `${opacity}`;
  });
};