// src/components/VideoThumbnail.tsx
import React from 'react';

interface VideoThumbnailProps {
    thumbnailSrc: string;
    hovertext: string;
    onClick: () => void;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ thumbnailSrc, hovertext, onClick }) => {
    return (
        <div className="video-thumbnail" onClick={onClick}>
            <img src={thumbnailSrc} className="img-fluid" />
            <div className="overlay">
            </div>
            <div className="hovertext">
                <div className="text">{hovertext}</div>
            </div>
        </div>
    );
};

export default VideoThumbnail;