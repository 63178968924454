// src/components/VideoPopup.tsx
import React, { useState, useEffect } from 'react';

interface VideoPopupProps {
    videoSrc: string;
    isVisible: boolean;
    onClose: () => void;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ videoSrc, isVisible, onClose }) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!isVisible) {
            setIsClosing(false);
        }
    }, [isVisible]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 500); // Match the duration of the closing animation
    };

    if (!isVisible && !isClosing) return null;

    return (
        <div className={`video-popup ${isClosing ? 'closing' : ''}`}>
            <div className="video-popup-overlay" onClick={handleClose}></div>
            <div className={`video-popup-content ${isClosing ? 'closing' : ''}`}>
                <button className="video-popup-close" onClick={handleClose}><i className="bi bi-x-circle-fill"></i></button>
                <video controls src={videoSrc} autoPlay></video>
            </div>
        </div>
    );
};

export default VideoPopup;