import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Info.css';
import { applyScrollEffect } from '../utils/scrollEffects';

const Info: React.FC = () => {
  useEffect(() => {
    // Set the document title
    document.title = 'mute-un-mute | Info';

    // Create the canonical link element
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    canonicalLink.href = 'https://mute-un-mute.com/info';

    // Append the canonical link to the head
    document.head.appendChild(canonicalLink);

    window.addEventListener('scroll', applyScrollEffect);
    applyScrollEffect(); // Initial call to set the sizes correctly
    return () => {
      window.removeEventListener('scroll', applyScrollEffect);
      document.head.removeChild(canonicalLink);
    }
    }, []);

  return (
    <div>
      <Helmet>
        <meta name="description" content="Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists." />
        <meta name="keywords" content="music production, hong kong, songwriting, music arrangements, Josh Yeung, mute-un-mute, mute-unmute, mute, unmute, j0sh, music producer, music production services, JKNC, commercial music, tvc music" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Mute-Un-Mute",
              "url": "http://www.mute-un-mute.com",
              "logo": "http://www.mute-un-mute.com/logo.png",
              "sameAs": "https://www.instagram.com/mute_un.mute",
              "founder": {
                "@type": "Person",
                "name": "Josh Yeung"
              },
              "description": "Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists."
            }
          `}
        </script>
      </Helmet>
      <h1>Info</h1>
      <div className="introduction">
        <p>MUTE-UN-MUTE is a Hong Kong based creative sound and music production house lead by Josh Yeung. Josh is a music composer and producer with a vast range in versatility and taste when it comes to composing sound for moving image, marketing campaigns, musical artists, and storytelling building audience engagement.  Conceptually focused and insightful in finding a unique sonic palette for various medias and projects, Josh has worked on numerous commercial projects including composition of songs and jingles, scoring, and popular music arrangements, touring musician and producing. As a composer and producer, Josh has created music for the brands such as:</p>
        </div>
      <div className="brands">
        <ul><li>Nike</li>
          <li>Adidas</li>
          <li>YSL</li>
          <li>Vans</li>
          <li>Redbull</li>
          <li>Mitsubishi</li>
          <li>China Mobile</li>
          <li>GU</li>
          <li>COS</li>
          <li>Shu Uemura</li>
          <li>Nowness Asia</li>
          <li>Greenpeace HK</li>
          <li>Cartier</li>
          <li>Rohto</li>
          <li>Estée Lauder</li>
          <li>Mentholatum</li>
          <li>Bifesta</li>
          <li>Hadalabo</li>
          <li>Detour HK</li>
          <li>Tai Kwun</li>
          <li>TUMI</li>
          <li>Razer</li>
          <li>Determinant HK</li>
          <li>7-11</li>
          <li>Arome</li>
          <li>Genki Sushi</li>
          <li>Yakiniku Like</li>
          <li>China Life HK</li>
          <li>Swire</li>
          <li>Hong Kong Jockey Club</li>
          <li>Hong Kong Resort Company</li>
          <li>Ocean Park HK</li>
          <li>VS salon</li>
          <li>HKYDC</li></ul>
      </div>
      <div className="paragraphs">
      <p>He has composed, performed or arranged for artists in HK and Asia such as: Vincy Chan, Charmaine Fong, Kelly Chan, Joyce Cheng, Bibi Zhou, Jun Kung, Pong Nan, Alfred Hui, and ViuTV’s 全民做星.</p>
           
      <p>With his group JKNC, he is currently creating a blend of electronic music with jazz influences and has performed at notated festivals in Hong Kong such as West Kowloon Freespace Jazz Fest and Clockenflap.</p>

      <p>His previous band NI.NE.MO was also active in the Hong Kong indie scene, where Josh was guitarist, and producer. NI.NE.MO has played numerous shows in the early to mid 2010s, headlining and sharing the stage along bands and artists such as Toro Y Moi, Tame Impala, LMF, Sunni Colon, Hello Nico, Yellow Fang, Queen Sea Big Shark, Da Bang, *aquarifa and so on. Their album Afterparty was released in 2013.</p>
    </div> </div>
  );
};

export default Info;
