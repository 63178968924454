import React, { useState } from 'react';
import './Navbar.css';

const Navbar: React.FC = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDropdownVisible(!isDropdownVisible);
        event.currentTarget.classList.toggle("change");
    };

    return (
        <nav className="navbar">
            <div className="logo-container">
                <a href="/"><img src={`images/logo.svg`} className="logo" /></a>
            </div>
            <ul className={`nav-links ${isDropdownVisible ? 'visible' : ''}`}>
                <a href="/info"><li>Info</li></a>
                <a href="/contact"><li>Contact</li></a>
            </ul>
            <div className="menu-icon" onClick={toggleDropdown}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        </nav>
    );
};

export default Navbar;