// src/pages/MainPage.tsx
import React, { useEffect } from 'react';
import VideoThumbnail from '../components/VideoThumbnail';
import VideoPopup from '../components/VideoPopup';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Main.css';

interface Video {
    src: string;
    thumbnail: string;
    hovertext: string;
}

const videos: Video[] = [
    { src: 'videos/feature1.mp4', thumbnail: 'images/thumbnail_f1.jpg', hovertext: 'Shu Uemura' },
    { src: 'videos/feature2.mp4', thumbnail: 'images/thumbnail_f2.jpg', hovertext: '' },
    { src: 'videos/feature3.mp4', thumbnail: 'images/thumbnail_f3.jpg', hovertext: '' },

    { src: 'videos/video1.mp4', thumbnail: 'images/thumbnail1.png', hovertext: 'Yakiniku Like' },
    { src: 'videos/video2.mp4', thumbnail: 'images/thumbnail2.png', hovertext: 'YDC 2023' },
    { src: 'videos/video3.mp4', thumbnail: 'images/thumbnail3.jpg', hovertext: 'Ocean park Halloween' },
    { src: 'videos/video4.mp4', thumbnail: 'images/thumbnail4.jpg', hovertext: 'Amazon Kindle China' },
    { src: 'videos/video5.mp4', thumbnail: 'images/thumbnail5.jpg', hovertext: 'Evisu' },
    { src: 'videos/video6.mp4', thumbnail: 'images/thumbnail6.jpg', hovertext: 'Greenpeace HK' },
    { src: 'videos/video7.mp4', thumbnail: 'images/thumbnail7.jpg', hovertext: 'Genki Sushi' },
    { src: 'videos/video8.mp4', thumbnail: 'images/thumbnail8.jpg', hovertext: 'Estée Lauder' },
    { src: 'videos/video9.mp4', thumbnail: 'images/thumbnail9.jpg', hovertext: 'COS' },
    { src: 'videos/video10.mp4', thumbnail: 'images/thumbnail10.jpg', hovertext: 'Evisu' },
    { src: 'videos/video11.mp4', thumbnail: 'images/thumbnail11.jpg', hovertext: 'China Mobile TVC' },
    { src: 'videos/video12.mp4', thumbnail: 'images/thumbnail12.jpg', hovertext: 'Bifesta TVC' },
    { src: 'videos/video13.mp4', thumbnail: 'images/thumbnail13.jpg', hovertext: 'YSL' },
    // Add more videos as needed
];

const MainPage: React.FC = () => {
    const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

    const handleThumbnailClick = (videoSrc: string) => {
        setSelectedVideo(videoSrc);
    };

    const handleClosePopup = () => {
        setSelectedVideo(null);
    };

    useEffect(() => {
        document.title = 'mute-un-mute | Main';

        // Create the canonical link element
        const canonicalLink = document.createElement('link');
        canonicalLink.rel = 'canonical';
        canonicalLink.href = 'https://mute-un-mute.com/';

        // Append the canonical link to the head
        document.head.appendChild(canonicalLink);

        const thumbnails = document.querySelectorAll('.video-thumbnail');
        thumbnails.forEach((thumbnail, index) => {
            setTimeout(() => {
                thumbnail.classList.add('appear');
            }, index * 100); // Adjust the delay as needed
        });
        // Cleanup function to remove the canonical link when the component unmounts
        return () => {
            document.head.removeChild(canonicalLink);
        };
    }, []);



    return (
        <div>
            <Helmet>
                <meta name="description" content="Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists." />
                <meta name="keywords" content="music production, hong kong, songwriting, music arrangements, Josh Yeung, mute-un-mute, mute-unmute, mute, unmute, j0sh, music producer, music production services, JKNC, commercial music, tvc music" />
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Mute-Un-Mute",
              "url": "http://www.mute-un-mute.com",
              "logo": "http://www.mute-un-mute.com/logo.png",
              "sameAs": "https://www.instagram.com/mute_un.mute",
              "founder": {
                "@type": "Person",
                "name": "Josh Yeung"
              },
              "description": "Mute-Un-Mute, led by Hong Kong music producer Josh Yeung, offers top-tier music composition, arrangement, and production services for commercials and artists."
            }
          `}
                </script>
            </Helmet>
            <div className="app">
                <div className="video-thumbnails">
                    {videos.map((video, index) => (
                        <VideoThumbnail
                            key={index}
                            thumbnailSrc={video.thumbnail}
                            hovertext={video.hovertext}
                            onClick={() => handleThumbnailClick(video.src)}
                        />
                    ))}
                </div>
                <VideoPopup
                    videoSrc={selectedVideo || ''}
                    isVisible={selectedVideo !== null}
                    onClose={handleClosePopup}
                />
            </div>
        </div>
    );
};

export default MainPage;